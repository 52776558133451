<template>
  <div class="queryContainer van-page-bg-color global-div">
    <div class="applyCollapse">
      <van-collapse v-model="tableData">
        <van-collapse-item v-for="(v, k) in modulelist"
                           :key="k"
                           :name="'a' + k">
          <template #title>
            <div class="apply-collapse-text-box">
              <span class="apply-collapse-text">{{ v.moduletypename }}(</span>
              <span class="apply-collapse-text apply-collapse-blue">{{
                v.list.length
              }}</span>
              <span class="apply-collapse-text">)</span>
            </div>
          </template>
          <div class="rowGrid">
            <van-grid :column-num="5"
                      :border="false">
              <van-grid-item v-for="(value, key) in v.list"
                             :key="key"
                             class="van-click-event"
                             @click="jump(value)"
                             style="text-align:center;">
                <template #icon>
                  <van-image v-if="ioclist.indexOf(value.moduleno.toString()) > -1"
                             :src="
                      require('@assets/img/q&s/q/' + value.moduleno + '.png')
                    "
                             :scale="1"
                             :width="'60%'"></van-image>
                  <van-image v-else
                             :src="require('@assets/img/q&s/q/0.png')"
                             :width="'60%'"
                             :scale="1"></van-image>
                </template>
                <template #text>
                  <span class="van-grid-item__text">{{
                    value.modulename
                  }}</span>
                </template>
              </van-grid-item>
            </van-grid>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/store/index.js'
import { getReportList, getPreViewParamList } from '@/api/wxrp.js'
import {
  sleep,
  sysToast,
  setData,
  getData,
  delData,
  // openIfrm,
  getQueryStr
} from '@/utils/index.js'
import { getModuleList, getSalarySignParam } from '@/api/wx.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  setup () {
    const store = useStore()
    return {
      store
    }
  },
  data () {
    return {
      userInfo,
      tabList: [
        {
          // id必须存在，可以是字符串或数字
          id: '0',
          name: '数据查询'
        },
        {
          id: '1',
          name: '报表中心'
        }
      ],
      tabIndex: 0,
      modelData: [],
      tableData: [],
      reportList: [], //报表列表
      reportList2: [], //报表列表
      neolist: [], //实际显示报表列表
      neoListBk: [],
      tableDataBk: [],
      searchVal: '',
      qp: true, // 全屏显示报表
      modulelist: [],
      V3url: './',
      ioclist: [
        '0',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '101',
        '1010',
        '1011',
        '1012',
        '102',
        '103',
        '104',
        '201',
        '202',
        '203',
        '204',
        '205',
        '206',
        '207',
        '208',
        '209',
        '210',
        '212',
        '213',
        '214',
        '215',
        '216',
        '217',
        '218',
        '219',
        '220',
        '221',
        '222',
        '223',
        '304',
        '403',
        '508',
        '601',
        '602',
        '604',
        '605',
        '606',
        '607',
        '608',
        '609',
        '610',
        '611',
        '612',
        '613',
        '614',
        '615',
        '616',
        '617',
        '618',
        '619',
        '661',
        '701',
        '702',
        '703',
        '704',
        '705',
        '706',
        '707',
        '708',
        '709',
        '721',
        '722',
        '728',
        '729',
        '730',
        '731',
        '732',
        '733',
        '734',
        '909'
      ],
      scroll: 0, // 记录滚动的距离
    }
  },
  created () {
    this.initData()
  },
  beforeRouteLeave (to, from, next) {
    // 获取滚动距离
    this.scroll = document.getElementById("app").scrollTop
    console.log(this.scroll, 'beforeRouteLeavebeforeRouteLeave')
    next()
  },
  activated () {
    // 当从详情返回时回到上次滚动距离
    this.$nextTick(() => {
      setTimeout(() => {
        document.getElementById("app").scrollTop = this.scroll
      }, 0)
    })
  },
  mounted () {
    setTimeout(() => {
      document.getElementsByClassName('van-tabbar-item')[0].style.color =
        '#646566'
      document.getElementsByClassName('van-tabbar-item')[2].style.color =
        '#1989fa'
    }, 200)
  },
  methods: {
    async initData () {
      // 查询列表
      await this.getModuleList().then(res => { })
    },
    async getModuleList () {
      await getModuleList({
        empcode: this.userInfo.empcode,
        langid: localStorage.getItem('langid')
      }).then(res => {
        this.modulelist = []
        let arr = [],
          moduletypename = null
        res.data.forEach((e, i) => {
          if (e.moduletypename != moduletypename)
          {
            moduletypename = e.moduletypename
            let obj = {
              moduletypename,
              open: 'a',
              list: []
            }
            obj.list = res.data.filter(
              item => item.moduletypename === moduletypename
            )
            arr.push(obj)
          }
        })
        this.modulelist = arr
        // 默认展开全部分组
        for (let i = 0; i < arr.length; i++)
        {
          this.tableData.push('a' + i)
        }
      })
    },
    jump (v) {
      // 先判断HR查询是否启用新版界面，1启用新版界面，
      // const use_new_hrquery = 1
      const use_new_hrquery = window.use_new_hrquery
      if (use_new_hrquery == 1 || use_new_hrquery == '1')
      {
        // 模块号=730，则跳转到“工资签收”的界面
        if (v.moduleno.toString() == '730')
        {
          // 查询是否首次打开
          getSalarySignParam({
            empcode: this.userInfo.empcode
          }).then(res => {
            let firstchangepwd = res.data[0].firstchangepwd
            let isfirstlogin = res.data[0].isfirstlogin
            let nosign = res.data[0].nosign
            if (isfirstlogin && firstchangepwd)
            {
              // 如果是首次打开并且 首次打开要强制修改密码 就去改密码界面
              this.$router.push({
                path: '/changeSalaryPsw',
                query: {
                  from: 'checkSalaryPwd',
                  firstchangepwd: firstchangepwd,
                  isfirstlogin: isfirstlogin,
                  nosign: nosign
                }
              })
            } else
            {
              this.$router.push({ path: '/salarySiginPassword' })
            }
          })
        } else if (
          ['9968', '9970', '9971'].indexOf(v.moduleno.toString()) > -1 &&
          window.APP_ID == 'wx6c71c581ceed4903'
        )
        {
          //佳成-佳成设备维护页
          this.$router.push({
            path: '/EquWH',
            query: { moduleno: v.moduleno, fromH5: true }
          })
        } else
        {
          this.$router.push({
            path: '/commHrQueryPage',
            query: { moduleno: v.moduleno, fromH5: true }
          })
        }
      } else
      {
        let href = '/weixinlocal/queryrpt/zhongzhuan.html'
        let url = '',
          Flag = v.templateid.toString(),
          moduleno = v.moduleno.toString()
        if (Flag == '0')
        {
          if (moduleno == '610')
          {
            url = 'shuaka.html'
          }
          if (moduleno == '615')
          {
            url = 'qianka.html'
          }
          if (moduleno == '611')
          {
            url = 'jiaban.html'
          }
          if (moduleno == '1005')
          {
            url = 'dingcan.html'
          }
          if (moduleno == '1007')
          {
            url = 'xiaofei.html'
          }
          if (moduleno == '205')
          {
            url = 'jiangcheng.html'
          }
          if (moduleno == '612')
          {
            url = 'qingjia.html'
          }
          if (moduleno == '660')
          {
            url = 'rikaoqin.html'
          }
          if (moduleno == '661')
          {
            url = 'yuekaoqin.html'
          }
          if (moduleno == '201')
          {
            url = 'dangan.html'
          }
          if (moduleno == '730')
          {
            url = 'gongzi.html'
          }
        } else
        {
          url = 'Template' + Flag + '.html'
        }
        let hyrptparas =
          v.modulename +
          '|' +
          url +
          '|' +
          v.templateid +
          '|' +
          v.moduleno +
          '|' +
          v.datequeryfield
        let body =
          'hyrptparas=' +
          hyrptparas +
          '&entcode=' +
          this.entcode +
          '&userinfo=' +
          getData('userInfo')
        if (href)
        {
          setLocVal('hyrptparas', hyrptparas) //判断版本   v3-->1   v2008-->0
          // moduleno == "730" 需要跳转到工资密码界面
          let href =
            this.V3url +
            '/weixinlocal/queryrpt/' +
            (hyrptparas.split('|')[3] === '730'
              ? 'checkcode.html'
              : hyrptparas.split('|')[1])
          this.$router.push({
            path: '/jump',
            query: { url: href, time: new Date().getTime() }
          })
        }
      }
    },
    handleEmitTabIndex (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style scoped lang="less">
.queryContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 142px;
  .rowGrid {
    font-size: 24px;
    font-family: Source Han Sans CN;
    color: #333;
    flex-wrap: nowrap !important;
    background: #fff;
    .van-grid-item {
      /deep/.van-grid-item__content {
        padding: 30px 0px;
        span {
          display: inline-block;
          max-width: 5em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .svg-icon {
      width: 74px;
      height: 74px;
      margin-bottom: 12px;
    }
  }
}

// #ifdef H5
.swiper-box {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 44px;
  width: 100%;
  height: calc(100vh - 94px);
  padding: 24rpx 0;
}

// #endif
// #ifndef H5
.swiper-vm-dom {
  margin-top: 56px;
}

// #endif
.apply-collapse-text {
  font-size: 30rpx;
  color: #000;
  font-weight: 900;
}

.apply-collapse-blue {
  color: #2b8af0 !important;
}

.apply-collapse-right {
  height: 56rpx;
  flex: 1;
  display: flex;
  flex-direction: row-reverse !important;
  align-items: center !important;
  padding-right: 24rpx;
}

.scroll-Y {
  width: 100%;
  height: 100%;
}

// 报表
.table-search {
  padding: 0 24rpx;
}

.table-search-content {
  width: 100%;
  background: #fff;
}

::v-deep {
  .is-input-border {
    border: 0;
  }

  .table-list {
    .van-collapse-content {
      padding-top: 0;
      padding-bottom: 0;
    }

    .van-cell__title {
      flex: 5;
    }
  }

  .van-collapse-item {
    margin-bottom: 24rpx;
  }
}

.table-list-wrap {
  margin-bottom: 24rpx;
}

.van-cell-text-title {
  font-size: 28rpx;
  color: #2b8df0;
  white-space: nowrap;
  word-break: break-all;
}

.applyCollapse {
  .van-grid-item {
    /deep/.van-grid-item__content {
      padding: 30px 0px;
      justify-content: start !important;
      span {
        max-width: 5em;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-box-orient: vertical !important;
        -webkit-line-clamp: 2 !important;
        white-space: normal !important;
      }
    }
  }
}
</style>
